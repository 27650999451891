<template>
    <div class="page paper-list">

        <div class="page-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>试卷中心</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="list-body clearfix">
            <leftSelect @select-match-lang="selectMatchAndLang" :leftMathIndex="leftMathIndex"></leftSelect>
            <div class="body-main">

                <mainSelect :matchAndLang="matchAndLangData"
                            :selectPaperNameByHome="paperName"
                            @send-selected-to-parent="getTestPaperBySelect"
                            @select-paper-type="selectPaperType"
                            @new-select-context="updateSelectPaperName"
                            @new-select-paper-month="SelectPaperByMonth"
                            @send-left-title-match="toLeftMathIndex"
                            @reset-select="resetSelectPaper"
                >
                </mainSelect>

                <allTestPaper :allTestPaperData="allTestPaperData" v-loading="loading"></allTestPaper>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total"
                               style="margin-top: 30px;text-align: center">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import titleSelct from "@/components/titleSelect.vue"
    import allTestPaper from "./components/allTestPaper.vue"
    // import allTestPaper2 from "./components/allTestPaper2.vue"
    import leftSelect from "@/components/leftSelect.vue"
    import mainSelect from "@/components/mainSelect.vue"

    import {
        getPaperList,
        getPaperByName
    } from "@/api/index.js"

    export default {
        components: {
            leftSelect,
            mainSelect,
            titleSelct,
            allTestPaper,
            // allTestPaper2
        },
        watch: {
            paperName: {
                handler: function (n, o) {
                    if (n !== undefined) {
                        this.getTestPaper();
                    }
                }
            },
            vipAnswer: {
                handler: function (n, o) {
                    this.getTestPaper();
                }
            },
        },
        data() {
            return {
                paperName: '',
                originalTop: 0,
                showPopup: 0,
                showType: 0,
                loading: false,
                allTestPaperData: [],
                allInfo: {
                    total: 0
                },
                matchParent: 'ALL',
                langParent: 'ALL',
                levelParent: 'ALL',
                vipAnswer: null,
                sortFlag: null,
                currentPage: 1,
                pageSize: 10,
                haveMore: false,
                noMore: false,
                isLoading: true,
                isActivePaperTypeFree: false,
                isActivePaperTypeVip: false,
                leftMathIndex: 0,
                matchAndLangData: {}
            };
        },

        methods: {
            selectMatchAndLang(data) {
                this.matchAndLangData = data;
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.currentPage = 1;
                this.getTestPaper()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getTestPaper()
            },
            changeSel(val) {
                this.nowType = val
            },
            changeShowType(val) {
                this.showType = val
            },
            resetData() {
                this.loading = false;
                this.allTestPaperData = [];
                this.allInfo.total = 0;
                this.currentPage = 1;
                this.pageSize = 10;
                this.haveMore = false;
                this.noMore = false;
                this.isLoading = true;
            },
            updateSelectPaperName(val) {
                this.paperName = val
            },
            selectPaperType(val) {
                this.vipAnswer = val;
            },
            SelectPaperByMonth(val) {
                this.sortFlag = val;
                this.getTestPaper();
            },
            toLeftMathIndex(val){
                this.leftMathIndex = val;
            },
            resetSelectPaper() {
                this.matchParent = 'ALL';
                this.langParent = 'ALL';
                this.levelParent = 'ALL';
                this.paperName = "";
                this.vipAnswer = null;
                this.sortFlag = null;
                this.getTestPaper();
            },
            getTestPaperBySelect(data) {
                this.matchParent = data.selectedMatch;
                this.langParent = data.selectedLang;
                this.levelParent = data.selectedLevel;

                this.getTestPaper();
            },

            getTestPaper(data) {
                let param = {
                    match: this.matchParent,
                    lang: this.langParent,
                    level: this.levelParent,
                    name: this.paperName,
                    vipAnswer: this.vipAnswer,
                    sortFlag: this.sortFlag,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                };

                getPaperList(param).then(res => {
                    if (res.code === "0000") {
                        this.allInfo = res.data
                        this.allTestPaperData = res.data.list
                    }
                    this.loading = false
                })
            }
        },

        mounted() {
            const params = this.$route.params;
            this.selectMatchAndLang(params);
            this.paperName = this.$route.query.searchPaperName;
        },

    };
</script>

<style lang="scss" scoped>

    .paper-list {
        width: 1200px;
        min-height: 700px;
    }

    .page-breadcrumb {
        margin: 15px 0;
        cursor: pointer;
    }

    .clearfix {
        display: block;
    }

    .list-body {

        margin: 0 auto;
    }


    .body-main {
        margin-left: 275px;
        /*flex: 1;*/
        display: block;
        max-width: 77%;
        min-height: 100vh;
        box-sizing: border-box;
        padding-bottom: 100px;

    }

</style>
