<template>
    <div style="box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);">
        <div v-if="allTestPaperData.length === 0" class="list-content" v-loading="loading" style="padding-bottom: 330px;background-color: #fff">
            <el-empty description="争分夺秒录入中....." style="background-color: #fff"></el-empty>
        </div>
        <div v-else class="list-content" v-for="(item, index) in allTestPaperData"
             :class="{ 'first-child': index === 0 }">
            <div class="content-detail" @click="jumpToDetails(item.id)">
                <div class="type-icon">
                    <span class="category normal-tag" v-if="item.vipAnswer === 0 ">免费</span>
                    <span class="category normal-tag2" v-else>VIP</span>

                    <span class="type" :style="{backgroundImage: `url(${icon.C})`}" v-if="item.langCode === 'C'"><div class="box icon" ></div></span>
                    <span class="type" :style="{backgroundImage: `url(${icon.Scratch})`}" v-else-if="item.langCode === 'Scratch'"><div class="box icon" ></div></span>
                    <span class="type" :style="{backgroundImage: `url(${icon.Python})`}" v-else-if="item.langCode === 'Python'"><div class="box icon" ></div></span>
                    <span class="type" :style="{backgroundImage: `url(${icon.Robot})`}" v-else-if="item.langCode === 'ROBOT'"><div class="box icon" ></div></span>
                    <span class="type" :style="{backgroundImage: `url(${icon.p0})`}" v-else><div class="box icon" ></div></span>
                </div>
                <div class="mid">
                    <div class="title" @click.stop.prevent="jumpToDetails(item.id)">
                        <a>{{item.name}}</a>
                    </div>
                    <div class="attribute">
                        <i v-for="label in item.paperLabels.split(',')">{{label}}</i>
                    </div>
                    <div class="album">
                        <span class="key">试卷描述：</span>
                        <div class="album-list">
                            <span>试卷编号：{{item.id}}</span>
                            <span>题目数量：{{item.questionCount}} </span>
                            <span>总分：{{item.score}} </span>
                            <span>建议时间：{{item.suggestTime}}分钟</span>
                        </div>
                    </div>
                    <div class="feature">
                        <!--  发布信息 ，题目数量 建议试卷-->
                        <span class="item">
                                        <em>{{item.createTime}} 发布</em>
                                    </span>
                        <span class="item">
                                        浏览量：<em>{{item.paperViewNum}}</em>
                                    </span>
                        <span class="item">
                                        下载量：<em>{{item.downloadNum}}</em>
                                    </span>
                        <span class="item">
                                        来源：system
                        </span>
                    </div>
                </div>

                <div class="open-paper">
                    <el-button type="primary"
                               style="width: 96px;height: 32px;text-align: center;line-height: 0"
                               @click.stop.prevent="jumpToDetails(item.id)">查看试卷
                    </el-button>
                    <div class="download">
                        <span class="el-icon-download" @click.stop.prevent="downloadInit(item.id,item.name)">下载</span>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
                title="选择文件类型"
                :visible.sync="centerDialogVisible"
                :lock-scroll="false"
                width="500px"
        >
            <div>
                <el-radio v-model="radio" label="1">试卷-PDF文件</el-radio>
                <el-radio v-model="radio" label="0" disabled>试卷-Word文件</el-radio>
                <el-radio v-model="radio" label="2">答案解析</el-radio>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="download()">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="26%"
                center
                :before-close="handleClose">
            <h3 style="text-align: center;font-size:26px;">{{ content }}</h3>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" style="text-align: center;font-size:20px;" @click="toSelfVIP"
                           round>升级会员，继续练习</el-button>

				<div style="padding-bottom: 20px"></div>
			</span>

        </el-dialog>
    </div>
</template>

<script>
    import {
        checkCountByAnswerPaperAndDay,
        paperDownCount,
        toDownload,
        toDownloadAnswer,
        selfInfo,
        toDownloadWord
    } from '@/api/index.js'

    export default {
        filters: {
            formatType(type) {
                switch (type) {
                    case 0:
                        return "Scratch"
                        break;
                    case 1:
                        return "C/C++"
                        break;
                    case 2:
                        return "Python"
                        break;
                    case 3:
                        return "NOIP"
                        break;
                    case 4:
                        return "机器人技术"
                        break;
                    case 5:
                        return "科技素养/计算思维"
                        break;
                    // case 6:
                    // 	return "无人机技术"
                    // 	break;
                    default:
                        break;
                }
            },
            formatLevel(level) {
                switch (level) {
                    case null:
                        return "全部"
                        break;
                    case 0:
                        return "模拟题"
                        break;
                    case 1:
                        return "一级"
                        break;
                    case 2:
                        return "二级"
                        break;
                    case 3:
                        return "三级"
                        break;
                    case 4:
                        return "四级"
                        break;
                    case 99:
                        return "练习题"
                        break;
                    case 404:
                        return "其他/四级以上"
                        break;
                    default:
                        break;
                }
            }
        },
        props: {
            allTestPaperData: {
                type: Array,
                default: () => [{}]
            }
        },
        data() {
            return {
                radio: '1',
                paperId: 0,
                filename: '',
                centerDialogVisible: false,
                loading: false,
                fullscreenLoading: false,
                dialogVisible: false,
                dialogVisible2: false,
                flag: false,
                content: "此作品未开通权限，不可操作！",
                // imageUrl: "../../../assets/paper/python-icon.png",
                icon:{
                    p0: require("@/assets/paper/p-icon.png"),
                    Scratch: require("@/assets/paper/scratch-icon.png"),
                    C: require("@/assets/paper/c-icon.png"),
                    Python: require("@/assets/paper/python-icon.png"),
                    Robot: require("@/assets/paper/robot-icon.png"),
                }

            }
        },
        methods: {
            handleClose(done) {
                this.dialogVisible = false;
            },
            toSelfVIP() {
                this.$router.push({path: "/selfVIP"});
                this.dialogVisible = false;
            },
            jumpToDetails(id) {
                if (this.flag === false) {
                    this.dialogVisible2 = false
                    checkCountByAnswerPaperAndDay(id).then(res => {
                        if (res.message === "此作品未开通权限，用户不可操作！") {
                            this.content = "此作品未开通权限，不可操作！";
                            this.dialogVisible = true
                        } else if (res.message === "答题次数达到每日答题上限！可升级会员!") {
                            this.content = "您好，今日答题次数已达到上限！";
                            this.dialogVisible = true
                        } else if (res.message === "此作品未开通权限，用户不可操作") {
                            this.content = "此作品未开通权限，不可操作!";
                            this.dialogVisible = true
                            this.dialogVisible2 = true
                        } else {
                            if (res.code === "0000") {
                                let routeData = this.$router.resolve({
                                    path: "/testPaperDetails?id=" + id
                                });
                                window.open(routeData.href, '_blank');
                            } else {
                                this.$message({
                                    message: res.message,
                                    type: 'warning'
                                });
                            }
                        }
                    })
                }

            },

            downloadInit(id, filename) {
                this.filename = filename
                this.paperId = id
                selfInfo().then(res => {
                    if (res.code === "0000") {
                        this.centerDialogVisible = true
                    } else {
                        this.centerDialogVisible = false
                    }
                });
            },
            download() {
                this.centerDialogVisible = false

                if (this.radio === '0') {
                    this.downloadPaperWord(this.paperId, this.filename)
                } else if (this.radio === '2') {
                    this.downloadAnswer(this.paperId, this.filename)
                } else {
                    this.downloadPaper(this.paperId, this.filename)
                }
            },

            downloadPaperWord(id, filename) {
                this.loading = true
                paperDownCount(id).then(res => {
                    if (res.code === '0000') {
                        this.$message({
                            message: '开始下载...',
                            type: 'success'
                        });
                        toDownloadWord(id, filename).then(res => {
                            // 从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
                            // var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
                            // var contentDisposition = decodeURI(res.headers['content-disposition'])
                            // var result = patt.exec(contentDisposition)
                            // var fileName = result[1]
                            // fileName = fileName.replace(/\"/g, '')
                            // console.log(fileName)
                            //以下均为后台请求返回成功后对返回结果的处理，处理完成会从浏览器显示下载
                            let blob = new Blob([res]);//response是返回的数据对象
                            let downloadElement = document.createElement('a');
                            let href = window.URL.createObjectURL(blob); //创建下载的链接
                            downloadElement.href = href;
                            downloadElement.setAttribute('download', filename + ".doc")
                            document.body.appendChild(downloadElement);
                            downloadElement.click(); //点击下载
                            document.body.removeChild(downloadElement); //下载完成移除元素
                            window.URL.revokeObjectURL(href); //释放掉blob对象

                        }).catch(() => {
                            this.$message({
                                message: "您暂无文件下载权限 或 下载次数已达今日上限 ！",
                                type: 'warning'
                            });
                        });

                    } else {
                        if (res.message === "此作品未开通权限，用户不可操作") {
                            this.content = "此作品未开通权限，不可操作!";
                            this.dialogVisible = true
                            this.dialogVisible2 = true
                        } else if (res.message === "您暂无文件下载权限 或 会员时间已到期 ！") {
                            this.content = "您暂无文件下载权限 或 会员时间已到期！";
                            this.dialogVisible = true
                            this.dialogVisible2 = true
                        } else if (res.message === "下载次数已达今日上限，明日再来哈!") {
                            this.$message({
                                message: '试卷下载次数已达今日上限，明日再来哈!',
                                type: 'warning'
                            });
                        } else {
                            this.$message({
                                message: res.message,
                                type: 'warning'
                            });
                        }

                    }
                    this.loading = false
                }).catch(() => {

                    this.dialogVisible = true
                    this.dialogVisible2 = true
                    this.loading = false
                });
            },
            downloadPaper(id, filename) {

                this.loading = true
                paperDownCount(id).then(res => {
                    if (res.code === '0000') {
                        this.$message({
                            message: '开始下载...',
                            type: 'success'
                        });
                        toDownload(id, filename).then(res => {
                            // 从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
                            // var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
                            // var contentDisposition = decodeURI(res.headers['content-disposition'])
                            // var result = patt.exec(contentDisposition)
                            // var fileName = result[1]
                            // fileName = fileName.replace(/\"/g, '')
                            // console.log(fileName)
                            //以下均为后台请求返回成功后对返回结果的处理，处理完成会从浏览器显示下载
                            let blob = new Blob([res]);//response是返回的数据对象
                            let downloadElement = document.createElement('a');
                            let href = window.URL.createObjectURL(blob); //创建下载的链接
                            downloadElement.href = href;
                            downloadElement.setAttribute('download', filename + ".pdf")
                            document.body.appendChild(downloadElement);
                            downloadElement.click(); //点击下载
                            document.body.removeChild(downloadElement); //下载完成移除元素
                            window.URL.revokeObjectURL(href); //释放掉blob对象

                        }).catch(() => {
                            this.$message({
                                message: "您暂无文件下载权限 或 下载次数已达今日上限 ！",
                                type: 'warning'
                            });
                        });

                    } else {
                        if (res.message === "此作品未开通权限，用户不可操作") {
                            this.content = "此作品未开通权限，不可操作!";
                            this.dialogVisible = true
                            this.dialogVisible2 = true
                        } else if (res.message === "您暂无文件下载权限 或 会员时间已到期 ！") {
                            this.content = "您暂无文件下载权限 或 会员时间已到期！";
                            this.dialogVisible = true
                            this.dialogVisible2 = true
                        } else if (res.message === "下载次数已达今日上限，明日再来哈!") {
                            this.$message({
                                message: '试卷下载次数已达今日上限，明日再来哈!',
                                type: 'warning'
                            });
                        } else {
                            this.$message({
                                message: res.message,
                                type: 'warning'
                            });
                        }

                    }
                    this.loading = false
                }).catch(() => {

                    this.dialogVisible = true
                    this.dialogVisible2 = true
                    this.loading = false
                });

            },

            downloadAnswer(id, filename) {
                this.$message({
                    message: '开始下载',
                    type: 'success'
                });
                this.loading = true
                toDownloadAnswer(id, filename).then(res => {
                    // 从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
                    // var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
                    // var contentDisposition = decodeURI(res.headers['content-disposition'])
                    // var result = patt.exec(contentDisposition)
                    // var fileName = result[1]
                    // fileName = fileName.replace(/\"/g, '')
                    // console.log(fileName)
                    console.log(res)
                    //以下均为后台请求返回成功后对返回结果的处理，处理完成会从浏览器显示下载
                    let blob = new Blob([res]);//response是返回的数据对象
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.setAttribute('download', filename + "-参考答案.pdf")
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                }).catch(err => {
                    this.content = "您暂无文件下载权限 或 会员时间已到期！";
                    this.dialogVisible = true
                    this.dialogVisible2 = true
                });
                this.loading = false
            }
        }

    }
</script>

<style lang="scss" scoped>

    .list-content {
        background: #fff;
        /*border-radius: 0 0 6px 6px;*/
        height: 152px;
        border-top: 1px dotted #ccc;

        .content-detail {
            height: 152px;
            position: relative;
            padding: 16px 20px 20px;

        }


        &:hover {
            /*border-radius: 4px;*/
            border: 1px solid #0081FF;
        }
    }

    .first-child {
        border-top: 1px dotted transparent;
    }


    .content-detail:before {
        position: absolute;
        top: -2px;
        height: 1px;

        left: 20px;
        right: 20px;
        content: "";


    }


    .type-icon {
        position: relative;
        float: left;
        width: 70px;
        height: 109px;
        margin: 6px 20px 0 0;

        .category {
            position: absolute;
            top: 16px;
            left: -1px;
            z-index: 2;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 12px;
            padding: 0 4px;
            height: 18px;
            line-height: 17px;
            border-radius: 3px 3px 3px 0px;
            margin-right: 6px;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
        }

        .normal-tag {
            background: #18BD7A;
        }

        .normal-tag2 {
            background: #FF6666;
        }

        .type {
            position: relative;
            display: block;
            margin: 8px auto 0;
            width: 64px;
            height: 86px;
            /*background: pink;*/
            /*background-image: url('{{imageUrl}}');*/
            /*background-image: url(../../../assets/paper/python-icon.png);*/
            background-repeat: no-repeat;
            background-size: 64px 86px;
            background-position: left top;
            /* background-attachment: fixed;背景固定 */
            text-align: center;
            font-family: "iconfont" !important;
            font-size: 13px;
            /* font-style: normal; */
            /* -webkit-font-smoothing: antialiased; */
            color: #ddd;
            cursor: pointer;
        }

        .icon {
            position: absolute;
            top: 26px;
            left: -1px;
            right: 0;
            width: 10px;
            height: 10px;
            /* margin: 0 auto; */
            font-size: 86px;
            line-height: 1;

            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden;
            opacity: 0.7;
        }

        .box {
            left: -8px;
            top: 21px;
            width: 0px;
            height: 0px;
            border: 5px solid transparent;
            /* border-top: 10px solid red;
            border-bottom: 10px solid yellow; */
            /* border-left: 10px solid green; */
            border-right: 5px solid black;
        }
    }

    .mid {
        float: left;
        width: 680px;
        margin-top: 4px;
        /*background-color: #00a4ff;*/
        .title {
            position: relative;
            height: 20px;
            line-height: 20px;
            cursor: pointer;
            font-weight: bold;

            a {
                text-decoration: none;

                &:hover {
                    color: #0081FF;
                }
            }
        }

        .attribute {
            margin-top: 14px;
            font-size: 0;

            i {
                display: inline-block;
                background: #EFF1F4;
                border-radius: 2px;
                height: 18px;
                line-height: 18px;
                padding: 0 6px;
                font-size: 12px;
                font-weight: 400;
                color: #80848B;
                margin-right: 3px;
                vertical-align: middle;
                font-style: normal;
            }
        }

        .album {
            margin-top: 12px;
            position: relative;
            padding-left: 60px;
            line-height: 16px;
            width: 540px;

            .key {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 12px;
                font-weight: 400;
                color: #80848B;
                line-height: 17px;
            }

            .album-list {
                width: 480px;
                display: inline-block;
                margin-top: 2px;

                span {
                    color: #444;
                    font-size: 12px;
                    line-height: 12px;
                    margin-bottom: 10px;
                    display: inline-block;
                    padding-right: 15px;
                    /*width: 100%;*/
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        .feature {
            line-height: 16px;

            .item {
                display: inline-block;
                padding-right: 8px;
                font-size: 12px;
                color: #80848B;
                max-width: 300px;
                white-space: nowrap;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;

                em {
                    color: #80848B;
                    margin-left: 4px;
                }
            }
        }
    }

    .open-paper {
        float: right;
        padding-top: 28px;
        min-width: 90px;
        text-align: center;


        .download {
            margin-top: 15px;
            display: block;
            font-size: 14px;
            font-weight: 400;
            color: #80848B;
            line-height: 14px;
            cursor: pointer;
        }
    }
</style>
